import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import ContractsModuleRestData from '../Data/ContractsModuleRestData'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppContractsModuleRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContractsModuleSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .HeadingEamilContractsModule {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaContractsModuleStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaContractsModuleStyleOne{
    max-width: 1150px;
    padding: 0px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .SubParaPonits {
    color: #047cc2;
    padding: 10px 0px 10px 0px;
    font-weight: bold;
    font-size: 21px;
  }
`

export const AppContractsModuleRest = () => {
  return (
    <AppContractsModuleRestWapper>
      <div>
        {ContractsModuleRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContractsModuleSectionEmail'>
                <ReUsePtag para={data.ParaOne} paraStyle='LeadParaContractsModuleStyleOne' />
                <ReUsePtag para={data.ParaTwo} paraStyle='LeadParaContractsModuleStyle' />
                <ReUsePtag para={data.ParaThree} paraStyle='LeadParaContractsModuleStyle' />
                <ReUsePtag para={data.ParaFour} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaFive} paraStyle='LeadParaContractsModuleStyle' />
                <ReUsePtag para={data.ParaSix} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaSeven} paraStyle='LeadParaContractsModuleStyle' />
                <ReUsePtag para={data.ParaEight} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaNine} paraStyle='LeadParaContractsModuleStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppContractsModuleRestWapper>
  )
}
