import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppContractsModuleRest } from './AppContractsModuleRest'

const AppContractsModuleWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SpendAnalyticsSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .SpendAnalyticsNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .SpendAnalyticsSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppContractsModuleWithNav = () => {
  return (
    <AppContractsModuleWithNavWapper>
      <div className="SpendAnalyticsSection">
        <div className="SpendAnalyticsNav">
          <AppSideNavEmailName />
        </div>
        <div className="SpendAnalyticsSectionContent">
          <AppContractsModuleRest />
        </div>
      </div>
    </AppContractsModuleWithNavWapper>
  )
}
