import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Icon: <FaArrowCircleRight />,
    ParaOne: "Procurement and internal business stakeholders continually execute contracts with their supply base, generating new commercial obligations and associated risks.  These contractual documents often end up in a large physical or digital repository, which fails to capture the necessary metadata, essentially functioning as little more than a document warehouse.  In other words, having a document, and knowing what future obligations the respective contract commits your company to, are fundamentally very different issues.",
    ParaTwo: "Raindrop understands this challenge and solves it with technology to make your life easier and provide risk transparency.  With a few simple clicks, perform a bulk upload of your documents, which are rapidly extracted and digitized via Raindrop’s AI technology.  The valuable contractual metadata is pulled out, facilitating the ability to make informed decisions on a go-forward basis, reduce your business risk, and ensure contractual compliance.",
    ParaThree: "So how does Raindrop do it?  As Procurement practitioners ourselves, we built Raindrop right out of the box with Artificial Intelligence (AI) capabilities, which ‘sniff’ for vital contractual provisions in your documents.  This valuable metadata enables visibility into your contractual commitments in a rapidly available manner.",
    ParaFour: "Join us and let’s take a dive into how Raindrop helps to maximize contract performance.",
    HeadingOne: "Intelligent Contract Workflow for Maximizing Contractual Performance",
    ParaFive: "Raindrop’s comprehensive Contract Module has a fully integrated e-signature solution functionality, helping create a seamless workflow for the preparation and management of Agreements.  One of Raindrop’s ‘secret sauces’ is our workflow functionality, which drastically simplifies the process from initial contract requests to renewals, helping to reduce bottlenecks by connecting the right people at the right time with integrated automated alerts and contract notifications.  Raindrop’s workflow functionality can be set up to anticipate and notify you of critical milestones, critical dates, and routes according to your company’s desired approval levels.",
    ParaSix: "Complete transparency and accountability are achieved in contract performance management by visually tracking submitted documents' entire path.  Internal stakeholders can receive automatic alerts as the contract approval process moves forward from task to task.  The entire contractual process is streamlined, automating manual tasks, and eliminating risks and errors with Raindrop's easily scalable Contract Module.",
    HeadingTwo: "Automated Data Extraction from Contracts",
    ParaSeven: "Raindrop’s contract data extraction technology is specifically built with artificial intelligence (AI) and machine learning (ML) to enable seamless data extraction, analysis, and management of data sets from simple to complex contract documents of all kinds.  Large volumes of files are imported into the Contract Management Module to initiate the data extraction process.  Raindrop’s AI technology extracts a wide variety of elements and contractual terms, saving countless hours of parsing through endless contract files.",
    ParaEight: "The extracted data is automatically exported and conveniently displayed.  Now Procurement and the internal stakeholders can see key contractual terms such as financial obligations, renewal terms, parent-child contract structure, contract start and end dates, renegotiation notification, and much more.  Raindrop makes it possible to quickly and easily digitize any contract and removes a large degree of risk while providing new contractual and risk awareness levels.",
    HeadingThree: "Wrapping It Up:",
    ParaNine: "Raindrop’s easy-to-use Contract Module provides users the flexibility to manage their complete contract lifecycle in an intuitive and easy-to-use interface.  Automate manual tasks, eliminate risks and errors with easily scalable contract management software, which plumbs directly into Raindrop’s Sourcing and Payables Modules.  Streamline your entire contract lifecycle process and eliminate risks with Raindrop’s easily scalable Contract Module today!",
  },
]
